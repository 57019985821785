<template>
    <div>
        <psi-sidebar-navigation
            v-if="menus.sidebar"
            :section-text="$_.get(authUser, 'company.name', '')"
            :items="menus.sidebar"
            :navigation-class="navigationClass"
            :drawer="drawer"
            @update:drawer="$emit('update:drawer', $event)"
        ></psi-sidebar-navigation>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "app-sidebar",
    components: {},
    props: {
        drawer: {
            validator: (val) => {
                return val === null || typeof val === "boolean";
            },
            required: true,
        },
    },

    data() {
        return {
            navigationClass: {
                background: this.$config("app.layoutColors.sideBarBackground"),
                listItem: this.$config("app.layoutColors.sideBarBackground"),
                groupActive: this.$config(
                    "app.layoutColors.activeGroupItemClass"
                ),
                listItemText: this.$config("app.layoutColors.sideBarGroupText"),
            },
        };
    },
    computed: {
        ...mapGetters("Users", ["authUser", "menus"]),
    },

    methods: {},
    mounted() {},
};
</script>

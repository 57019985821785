var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.menus.sidebar
        ? _c("psi-sidebar-navigation", {
            attrs: {
              "section-text": _vm.$_.get(_vm.authUser, "company.name", ""),
              items: _vm.menus.sidebar,
              "navigation-class": _vm.navigationClass,
              drawer: _vm.drawer
            },
            on: {
              "update:drawer": function($event) {
                return _vm.$emit("update:drawer", $event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }